import Layout from 'components/common/Layout';
import { Login } from 'pages/Login';
import Settings from 'pages/Settings';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthRoute from './components/common/AuthRoute';
import ActionRoute from './components/common/ActionRoute';
import CustomerUsers from 'pages/CustomerUsers';
import { ResetPassword } from 'pages/Login';
import Subscriptions from 'pages/Subscriptions';
import Systems from 'pages/Systems';
import System from 'pages/System';
import AdminRoute from 'components/common/AdminRoute';
import Account from 'pages/Settings/Account';
import Credits from 'pages/Settings/Credits';
import Pua from 'pages/Settings/Pua';
import PortalUserAgreement from 'pages/Legal/PortalUserAgreement';
import useCustomerUser from 'hooks/useCustomerUser';
import Privacy from 'pages/Settings/Privacy';
import useAnalytics from 'hooks/useAnalytics';
import Imprint from 'pages/Settings/Imprint';
import Notifications from 'pages/Settings/Notifications';
import Perpetuals from 'pages/Perpetuals';

function App() {
    useAnalytics();
    useCustomerUser();

    return (
        <Routes>
            <Route path="login" element={<AuthRoute redirectPath="/subscriptions"><Login /></AuthRoute>} />
            <Route path="resetPassword" element={<AuthRoute redirectPath="/subscriptions"><ResetPassword /></AuthRoute>} />
            <Route path="pua" element={<AuthRoute><PortalUserAgreement /></AuthRoute>} />
            <Route path="action" element={<ActionRoute />} />
            <Route element={<AuthRoute><Layout /></AuthRoute>}>
                <Route path="" element={<AuthRoute><Navigate to="/subscriptions" /></AuthRoute>} />
                {/* <Route path="perpetuals" element={<AuthRoute><Perpetuals /></AuthRoute>} /> */}
                <Route path="subscriptions" element={<AuthRoute><Subscriptions /></AuthRoute>} />
                <Route path="systems">
                    <Route path="" element={<AuthRoute><Systems /></AuthRoute>} />
                    <Route path=":systemId">
                        <Route path="" element={<System />} />
                    </Route>
                </Route>
                <Route path="users" element={<AdminRoute><CustomerUsers /></AdminRoute>} />
                <Route path="settings" element={<AuthRoute><Settings /></AuthRoute>}>
                    <Route path="" element={<Navigate to="/settings/account" />} />
                    <Route path="account" element={<Account />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="imprint" element={<Imprint />} />
                    <Route path="terms-of-use" element={<Pua />} />
                </Route>
                <Route path="credits" element={<Credits />} />
                <Route path="privacy" element={<Privacy />} />
            </Route>
        </Routes>
    );
}

export default App;
