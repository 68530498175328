import {
    Badge,
    Box,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorMode,
    useColorModeValue,
} from "@lawo/lawo-ui";
import { useEffect } from "react";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import useCustomerNames from "../../../hooks/useCustomerNames";
import UserButtonPopover from "../UserButton/UserButtonPopover";
import Title from "components/common/Title";

const sortCustomerIdsByCustomerName = (customerIds: string[], customerNames: Map<string, string>) => {
    return customerIds.sort((a, b) => {
        const nameA = customerNames.get(a);
        const nameB = customerNames.get(b);
        if (nameA && nameB) {
            return nameA.localeCompare(nameB);
        }
        if (nameA) {
            return -1;
        }
        if (nameB) {
            return 1;
        }
        return a.localeCompare(b);
    });
};

const AppBar = () => {
    const routes: any = {
        // "/dashboard": "Dashboard",
        // "/perpetuals": "Perpetual",
        "/subscriptions": "Subscriptions",
        "/systems": "Systems",
        "/users": "Users",
        "/settings": "Settings",
    };

    const { customerAccountIds, currentCustomerAccountId, setCurrentCustomerAccountId, isAdmin } = useAuth();
    const customerNames = useCustomerNames(customerAccountIds);

    const location = useLocation();
    const route = Object.keys(routes).find((o) => location.pathname.startsWith(o));
    const activeRoute = routes[route ?? "/subscriptions"];

    const { colorMode } = useColorMode();
    const bgColor = useColorModeValue("#ffffff", "#1d1d1d");
    const buttonBackground = useColorModeValue("#f9f9f9", "#242424");
    const hoverColor = useColorModeValue("#eeeeee", "#282828");
    const expandedColor = { light: "white", dark: "black" };

    useEffect(() => {
        const storedCustomerAccountId = window.localStorage.getItem('customerAccountId');
        if (storedCustomerAccountId && customerAccountIds?.includes(storedCustomerAccountId)) {
            setCurrentCustomerAccountId(storedCustomerAccountId);
        } else {
            setCurrentCustomerAccountId(customerAccountIds[0]);
        }
    }, [customerAccountIds, setCurrentCustomerAccountId]);

    return (
        <Box
            as="header"
            position="relative"
            height="72px"
            width="100%"
            mb="4px"
            p="8px"
            bg={bgColor}
            borderRadius="8px"
            zIndex="101"
        >
            <Flex height="100%" align="center" data-test-id="global-header">
                <Title mr="16px" />
                <Box display={{ base: "block", xxl: "none" }}>
                    <Menu>
                        <MenuButton
                            bg={buttonBackground}
                            borderRadius="4px"
                            height="56px"
                            width="256px"
                            mr="8px "
                            px="8px"
                            lineHeight="24px"
                            _hover={{
                                bg: hoverColor,
                            }}
                            _expanded={{
                                bg: "lawoBlue.100",
                                color: expandedColor[colorMode],
                            }}
                            data-test-id="global-nav-menu-button"
                        >
                            <Flex direction="column" alignItems="start" justify="space-between">
                                <Text><b>{activeRoute}</b></Text>
                                <Text>Page</Text>
                            </Flex>
                        </MenuButton>
                        <MenuList borderRadius="16px" data-test-id="global-nav-container">
                            {/* <NavMenuItem to="/perpetuals" data-test-id="global-nav-perpetuals">
                                {routes["/perpetuals"]}
                            </NavMenuItem> */}
                            <NavMenuItem to="/subscriptions" data-test-id="global-nav-subscriptions">
                                {routes["/subscriptions"]}
                            </NavMenuItem>
                            <NavMenuItem to="/systems" data-test-id="global-nav-systems">
                                {routes["/systems"]}
                            </NavMenuItem>
                            {
                                isAdmin &&
                                <NavMenuItem to="/users" data-test-id="global-nav-users">
                                    {routes["/users"]}
                                </NavMenuItem>
                            }
                            <NavMenuItem to="/settings" data-test-id="global-nav-settings">
                                {routes["/settings"]}
                            </NavMenuItem>
                        </MenuList>
                    </Menu>
                </Box>
                {customerAccountIds?.length > 1 &&
                    <Menu>
                        <MenuButton
                            bg={buttonBackground}
                            borderRadius="4px"
                            height="56px"
                            width="256px"
                            px="8px"
                            lineHeight="24px"
                            _hover={{
                                bg: hoverColor,
                            }}
                            _expanded={{
                                bg: "lawoBlue.100",
                                color: expandedColor[colorMode],
                            }}
                        >
                            <Flex direction="column" alignItems="start" justify="space-between" minHeight="40px">
                                <Box>
                                    <b>{customerNames.get(currentCustomerAccountId)}</b>
                                </Box>
                                <Text>Account</Text>
                            </Flex>
                        </MenuButton>
                        <MenuList borderRadius="16px">
                            {
                                sortCustomerIdsByCustomerName(customerAccountIds, customerNames).map((id, index) => {
                                    return <AccountMenuItem to={id} key={index}>{customerNames.get(id) ?? id}</AccountMenuItem>
                                })
                            }
                        </MenuList>
                    </Menu>
                }
                <Flex ml="auto" gap="2px" alignItems="center">
                    <UserButtonPopover />
                    {
                        process.env.REACT_APP_EMULATE === "true" &&
                        <Badge m={2} colorScheme="purple">SIMULATOR</Badge>
                    }
                </Flex>
            </Flex>
        </Box>
    );
};

interface NavMenuProps {
    to: any;
    children: any;
}

const NavMenuItem = ({ to, children }: NavMenuProps) => {
    const { colorMode } = useColorMode();
    const expandedColor = { light: "white", dark: "black" };
    const match = useMatch({
        path: to,
        exact: false,
    });

    const activeProps = match
        ? {
            borderRadius: "8px",
            bg: "lawoBlue.100",
            color: expandedColor[colorMode],
            fontWeight: "bold",
            _hover: {
                bg: "lawoBlue.100",
                color: expandedColor[colorMode],
            },
            _focus: {
                bg: "lawoBlue.100",
                color: expandedColor[colorMode],
            },
        }
        : {
            _focus: {
                bg: "tabBackgroundHover",
            },
            bg: "tabBackground",
        };

    const navigate = useNavigate();

    return (
        <MenuItem
            h="40px"
            borderRadius="4px"
            {...activeProps}
            onClick={() => navigate(to)}
        >
            {children}
        </MenuItem>
    );
};

const AccountMenuItem = ({ to, children }: NavMenuProps) => {
    const { colorMode } = useColorMode();
    const expandedColor = { light: "white", dark: "black" };
    const { currentCustomerAccountId, setCurrentCustomerAccountId } = useAuth();
    const handleSelectCustomerAccountId = (id: string) => {
        setCurrentCustomerAccountId(id);
        window.localStorage.setItem('customerAccountId', id);
    };

    const activeProps = to === currentCustomerAccountId
        ? {
            // borderRadius: "8px",
            bg: "lawoBlue.100",
            color: expandedColor[colorMode],
            fontWeight: "bold",
            _hover: {
                bg: "lawoBlue.100",
                color: expandedColor[colorMode],
            },
            _focus: {
                bg: "lawoBlue.100",
                color: expandedColor[colorMode],
            },
        }
        : {
            _focus: {
                bg: "tabBackgroundHover",
            },
            bg: "tabBackground",
        };

    return (
        <MenuItem
            h="40px"
            borderRadius="8px"
            {...activeProps}
            onClick={() => handleSelectCustomerAccountId(to)}
        >
            {children}
        </MenuItem>
    );
}

export default AppBar;
